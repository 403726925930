<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card>
      <v-card-text justify="center" class="pt-5 pb-0">
        <p class="text-subtitle-1 black--text text-center">
          {{$t('share_title')}}
        </p>

        <div class="text-center">
          <ShareNetwork
            network="facebook"
            :url="
              `https://app.whirelab.com/candidate/${candidate.id}`
            "
            :title="candidate.fullName"
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            :quote="candidate.fullName"
            :hashtags="candidate.fullName"
          >
            <v-tooltip left color="blue">
              <template v-slot:activator="{ on }"> 
                <v-btn color="blue" v-on="on" class="text-decoration-none">
                  <v-icon color="white">mdi-facebook</v-icon>
                </v-btn>
              </template>
              <span>Facebook</span>
            </v-tooltip>
          </ShareNetwork>
          
          <ShareNetwork
            network="linkedin"
            :url="
              `https://app.whirelab.com/candidate/${candidate.id}`
            "
            :title="candidate.fullName"
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            :quote="candidate.fullName"
            :hashtags="candidate.fullName"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2" color="blue" v-on="on">
                  <v-icon color="white">mdi-linkedin</v-icon>
                </v-btn>
              </template>
              <span>Linkedin</span>
            </v-tooltip>
          </ShareNetwork>

          <ShareNetwork
            network="twitter"
            :url="
              `https://app.whirelab.com/candidate/${candidate.id}`
            "
            :title="candidate.fullName"
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            :quote="candidate.fullName"
            :hashtags="candidate.fullName"
          >
            <v-tooltip right class="text-decoration-none" >
              <template v-slot:activator="{ on }">
                <v-btn  color="blue" class="text-decoration-none" v-on="on">
                  <v-icon color="white">mdi-twitter</v-icon>
                </v-btn>
              </template>
              <span>twitter</span>
            </v-tooltip>
          </ShareNetwork>
          
        </div>
        <div class="d-flex flex-row pt-3">
           <v-tooltip v-model="show" top>
            <template v-slot:activator>
          <v-text-field
            v-model="linkShare"
            outlined
            dense
            readonly
            class="mr-2"
            @click:append="copyTestingCode"
          ></v-text-field>
              <v-btn 
                type="button" 
                text
                @click.stop.prevent="copyTestingCode"
              >
                {{$t('copy')}}
              </v-btn> 
            </template>
            <span>{{$t('copied')}}</span>
          </v-tooltip>
          <input type="hidden" id="testing-code" :value="linkShare">
        </div>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-row no-gutters>
          <v-col md="12" sm="12">
            <v-btn
              color="primary"
              text
              @click="finish()"
              block
              >{{$t('finish')}} </v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {  API_URL } from '../../../../../api';
export default {
  name: 'ShareDialog',
  data: () => ({
    dialog: false,
    linkShare: "",
    show: false,
    apiUrl:API_URL,
    candidate: {}
  }),
  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("shareCandidateCV", (candidate) => {
      this.dialog = true
      this.candidate = candidate
      // this.linkShare = `https://app2.whirelab.com/candidate/${candidate.id}`
        this.linkShare = this.apiUrl+`/pdf/localhost:8080_pdf_${candidate}`
    });
  },
  methods: {
    copyTestingCode () {
      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
      testingCodeToCopy.select()

      try {
        document.execCommand('copy');
        this.show = !this.show
        // var msg = successful ? 'successful' : 'unsuccessful';
        // alert('Testing code was copied ' + msg);
      } catch (err) {
        alert('Oops, unable to copy');
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    finish () {
      this.dialog = false;
      this.$emit('finish');
    }
  }
}
</script>