<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="300px"
  >
    <v-card>
      <v-card-title>
        {{$t('saveto')}}...
        <v-spacer></v-spacer>
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <template v-for="list in lists">
          <div class="d-flex flex-row justify-space-between align-center" :key="list.id">
            <div>
              <v-checkbox
                v-model="selected"
                :label="list.name"
                :value="list.id"
                hide-details
                @change="addCandidateList(list.id)"
              ></v-checkbox>
            </div>
            <v-spacer></v-spacer>
            <div class="mt-5"><v-icon color="error" @click="openConfirmeDialog(list.id)">mdi-close-circle</v-icon></div>
          </div>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          v-show="!create"
          block
          text
          @click="create = true"
        >
          <v-icon>mdi-plus</v-icon>
          <span class="text-lowercase">{{$t('createlist')}}</span>
        </v-btn>
        <v-row v-show="create">
          <v-col cols="12" md="12">
            <v-text-field
              v-model="name"
              :label="$t('form_candidate_name')"
              clearable
              :placeholder="$t('insertNamelist')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" class="text-end">
            <v-btn
              text
              color="primary"
              @click="createList()"
            >
              {{$t('create')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>

    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="$t('removeItemQ')"
      @yes="deleteList"
      @no="confirm = false"
    />

    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{$t('close')}}
        </v-btn>
      </template>
    </v-snackbar>

  </v-dialog>
</template>

<script>
import { 
  ADD_CANDIDATE_TO_LIST_MUTATION, 
  CREATE_CANDIDATE_LIST_MUTATION,
  DELETE_CANDIDATE_LIST_MUTATION,
  REMOVE_CANDIDATE_FROM_LIST_MUTATION
} from '../graphql/Mutation'
import { GET_CANDIDATE_LISTS_QUERY } from '../graphql/Query'
import dialogMixins from "@/mixins/dialog";
import { formatError } from "@/utils";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
export default {
  name: 'CandidateListDialog',
  components: { ConfirmDialog, ErrorDialog },
  props: {
    candidateId: String,
    dialog: Boolean
  },
  mixins: [dialogMixins],
  data: () => ({
    create: false,
    lastList: null,
    lists: [],
    name: '',
    selected: [],
    snackbar: false,
    text: 'Adicionado a ',
    timeout: 2000,
  }),
  apollo: {
    lists: {
      query: GET_CANDIDATE_LISTS_QUERY,
      fetchPolicy: "no-cache",
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.setInit()
      }
    }
  },
  methods: {
    async addCandidateList (id) {
      const selectedIndex = this.selected.findIndex(l => l == id)
      if (selectedIndex > -1) {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: ADD_CANDIDATE_TO_LIST_MUTATION,
            variables: { id, candidateId: this.candidateId }
          })
          const index = this.lists.findIndex(l => l.id == id)
          // this.lists.splice(index, 1, data.addCandidateList)
          this.lists[index].candidates = data.addCandidateToList.candidates
          this.lastList = id
          this.text = `${this.$t('candidate_add_fav')} ${data.addCandidateToList.name}`
          this.snackbar = true
        } catch (error) {
          this.error = formatError(error.message);
          this.showError = true;
        }
      } else {
        await this.removeCandidate(id)
      }
    },
    async createList () {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_CANDIDATE_LIST_MUTATION,
          variables: { candidateId: this.candidateId, name: this.name }
        })
        this.selected.push(data.createCandidateList.id)
        this.lists.push(data.createCandidateList)
        this.name = ''
        this.create = false
        this.text = `${this.$t('candidate_add_fav')} ${data.createCandidateList.name}`
        this.lastList = data.createCandidateList.id
        this.snackbar = true
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    async deleteList (id) {
      console.log(id);
      try {
        this.confirm = false;
        await this.$apollo.mutate({
          mutation: DELETE_CANDIDATE_LIST_MUTATION,
          variables: { id }
        })
        const selectedInex = this.selected.findIndex(s => s == id)
        this.selected.splice(selectedInex, 1)
        const index = this.lists.findIndex(l => l.id == id)
        this.lists.splice(index, 1)
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    openConfirmeDialog(id) {
      this.removeId = id;
      this.confirm = true;
    },
    async removeCandidate (id) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: REMOVE_CANDIDATE_FROM_LIST_MUTATION,
          variables: { id, candidateId: this.candidateId }
        })
        const index = this.lists.findIndex(l => l.id == id)
        this.lists.splice(index, 1, data.removeCandidateFromList)
        this.lastList = id
        this.text = `${this.$t('candidate_remove_fav')} ${this.lists[index].name}`
        this.snackbar = true
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    setInit () {
      this.lists.forEach(l => {
        const index = l.candidates ? l.candidates.findIndex(c => c == this.candidateId) : -1
        if (index > -1) {
          this.selected.push(l.id)
        }
      });
    }
  },
  beforeDestroy () {
    this.$emit('close')
  }
}
</script>