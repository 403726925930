<template>
  <v-card v-if="candidate">
    <v-row>
      <v-col cols="12" md="4">
        <v-list-item>
          <v-list-item-content>
            <v-card :elevation="0" max-height="310">
              <v-card flat fluid>
                <div style="height:150px">
                  <v-img
                    lazy-src="https://picsum.photos/id/11/10/6"
                    :src="
                      candidate.photo
                        ? `${apiUrl}/images/candidate/${candidate.photo}`
                        : `/avatar.png`
                    "
                    height="180px"
                  >
                  </v-img>
                </div>
                <v-card-text>
                  <div class="text-center py-4">
                    <v-list-item>
                      <v-list-item-content>
                        <strong
                           class="body font-weight-bold text-uppercase"
                        >
                          {{ candidate.fullName }}
                        </strong>
                        <div
                          class="text-subtitle-2 font-weight-medium text--disabled"
                        >
                          {{ candidate.profession }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row justify="center" v-if="candidate.media">
                          <v-btn
                            :href="
                              `//${candidate.media.facebook}`
                            "
                            target="_blank"
                            icon
                          >
                            <v-icon small color="primary">mdi-facebook</v-icon>
                          </v-btn>
                          <v-btn
                            :href="
                              `//${candidate.media.linkedin}`
                            "
                            target="_blank"
                            icon
                          >
                            <v-icon small color="primary">mdi-linkedin</v-icon>
                          </v-btn>
                          <v-btn
                            :href="
                              `//${candidate.media.twitter}`
                            "
                            target="_blank"
                            icon
                          >
                            <v-icon small color="primary">mdi-twitter</v-icon>
                          </v-btn>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-card-text>
              </v-card>
            </v-card>
          </v-list-item-content>
        </v-list-item>

        <div class="px-3" style="margin-top:0%">
          <p class="pt-3 body black--text font-weight-medium">
            {{ $t("form_candidate_tools") }}
          </p>
          <v-chip-group column>
            <v-chip
              v-for="(skill, index) in candidate.otherSkills"
              :key="index"
              label
              small
              >{{ skill }}</v-chip
            >
          </v-chip-group>
        </div>
        <div class="px-3">
          <p class="pt-5 body black--text font-weight-medium">
           {{ $t("form_candidate_inter") }}
          </p>
          <v-chip-group column multiple>
            <v-chip
              v-for="(area, index) in candidate.areasInterest"
              :key="index"
              label
              small
              >{{ $t(area) }}</v-chip
            >
          </v-chip-group>
        </div>
      </v-col>

      <v-col cols="12" md="8">
        <v-row class="d-none d-sm-flex py-4">
          <v-btn
            v-for="tab in tabs"
            :key="tab"
            dense
            text
            small
            class="caption text-none"
            :class="{ 'text--disabled': tab != 'Perfil' }"
            :color="tab == 'Perfil' ? 'primary' : ''"
          >
            {{ tab }}
            <v-icon small right>mdi-play</v-icon>
          </v-btn>
        </v-row>
        <div class="mt-5 pr-5">
          <div class="body mb-5 text-uppercase">{{ $t("about") }}</div>
          <p class="body-2 font-weight-regular grey--text">
            {{ candidate.presentation }}
          </p>
        </div>
        <v-divider class="my-6"></v-divider>
        <div
          class="d-flex flex-row justify-space-between py-3 pr-5 ajustaEspaco2"
          
        >
          <div class="text--secondary  body-2">  {{ $t("form_candidate_Availability") }}</div>
          <div class="primary--text  body-2">{{ $t(candidate.availability) }}</div>
        </div>
        <v-divider></v-divider>
        <v-row class="py-4">
          <!-- <v-col cols="12" md="6" class="py-0">
            <v-list-item two-line style="padding-left:0;">
              <v-list-item-content>
                <v-list-item-title class="body">1ª Lingua</v-list-item-title>
                <v-list-item-subtitle
                  class="body-2 font-weight-regular"
                  v-if="
                    candidate.languages &&
                      candidate.languages[0] &&
                      candidate.languages[0].name
                  "
                >
                  {{ candidate.languages[0].name }} -
                  {{ candidate.languages[0].level }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="body-2 font-weight-regular" v-else>
                  --- - ---
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col> -->
          <!-- <v-col cols="12" md="6" class="py-0">
            <v-list-item two-line style="padding-left:0;">
              <v-list-item-content class="py-0">
                <v-list-item-title class="body">2ª Lingua</v-list-item-title>
                <v-list-item-subtitle
                  class="body-2 font-weight-regular"
                  v-if="candidate.languages && candidate.languages[1]"
                >
                  {{ candidate.languages[1].name }} -
                  {{ candidate.languages[1].level }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="body-2 font-weight-regular" v-else
                  >--- - ---</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col> -->

          <v-col cols="12" md="6" class="py-0">
            <v-list-item two-line style="padding-left:0;">
              <v-list-item-content>
                <v-list-item-title class="body"
                  >{{
                  $t("professionalExperience")
                }}</v-list-item-title
                >
                <v-list-item-subtitle class="body-2 font-weight-regular py-2"
                  >{{
                    days(candidate.professionalExperience)
                  }}
                  {{$t('year')}}{{days(candidate.professionalExperience) > 1 ? 's':''}}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-list-item two-line style="padding-left:0;">
              <v-list-item-content>
                <v-list-item-title class="body"
                  >{{$t('Interviesalary')}}</v-list-item-title
                >
                <v-list-item-subtitle class="body-2 font-weight-regular py-2">
                  MT{{ candidate.basicSalary }} - MT{{ candidate.idealSalary }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="d-flex flex-row px-3 py-8">
          <div
            class="d-flex flex-row justify-space-between py-4 pr-5 ajustaEspaco"
            
          >
            <!-- <v-chip color="primary" label @click="dialogInterview = true"
              >Marcar entrevista</v-chip
            > -->
            <v-chip
              label
              outlined
              color="primary"
              class="mx-2"
              @click="goCV()"
              >{{ $t("view") }} CV</v-chip
            >
            <!-- <v-chip label outlined color="primary" @click="showCertificate()">
              Ver certificados
            </v-chip> -->
            <v-btn small text color="primary" @click="showCertificate()">
              {{ $t("view_cert") }}
            </v-btn>
          </div>
        </v-row>
        <v-row class="d-flex flex-row px-3 pb-4" v-if="certificate">
          <div
            class="d-flex flex-row px-2"
            v-for="(cert, index) in candidate.certificates"
            :key="index"
          >
            <div>
              <a :href="`${apiUrl}/files/certifications/${cert}`" target="_blank">{{ cert.length>20?cert.substring(0, 20)+'.pdf':cert  }}</a>
            </div>
          </div>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-4">
          <v-col cols="12" md="6" class="py-0">
            <v-list-item two-line style="padding-left:0;">
              <v-list-item-content>
                <v-list-item-title class="body black--text">E-mail</v-list-item-title>
                <v-list-item-subtitle class="body-2 font-weight-regular">{{
                  candidate.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-list-item two-line style="padding-left:0;">
              <v-list-item-content>
                <v-list-item-title class="body black--text">{{
                  $t("phone")
                }}</v-list-item-title>
                <v-list-item-subtitle class="body-2 font-weight-regular">{{
                  candidate.telephone
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-list-item two-line style="padding-left:0;">
              <v-list-item-content>
                <v-list-item-title class="body black--text"
                  >{{
                  $t("form_candidate_birth")
                }}</v-list-item-title
                >
                <v-list-item-subtitle class="body-2 font-weight-regular">{{
                  dateFormat(candidate.dateBirth)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-list-item two-line style="padding-left:0;">
              <v-list-item-content>
                <v-list-item-title class="body black--text">{{
                  $t("address")
                }}</v-list-item-title>
                <v-list-item-subtitle class="body-2 font-weight-regular">{{
                  candidate.address
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" md="12" class="pb-0 mb-0 my-3 black--text">{{
            $t("form_candidate_ref")
          }}</v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
            v-for="reference in candidate.references"
            :key="reference.fullName"
          >
            <v-list-item two-line style="padding-left:0;">
              <v-list-item-content>
                <v-list-item-subtitle class="body  black--text">{{
                  reference.fullName
                }}</v-list-item-subtitle>
                <v-list-item-subtitle class="body-2 font-weight-regular my-1">{{
                  reference.office
                }}</v-list-item-subtitle>
                <v-list-item-subtitle class="body-2 font-weight-regular my-3"><v-icon small>mdi-phone</v-icon> {{
                  reference.telephone
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <schedule-interview-dialog
      v-if="vacancyId"
      :dialog="dialogInterview"
      :selectedCandidates="[candidate]"
      :vacancyId="vacancyId"
      @close="dialogInterview = false"
    /> -->
    <ShareCVDialog />
  </v-card>
</template>

<script>
import { API_URL } from '@/api'
import { mapGetters } from 'vuex'
import yearsMixins from '@/mixins/years'
import moment from 'moment'
import ShareCVDialog from "../components/ShareCVDialog.vue";
export default {
  name: 'ProfileDetails',
  components: { ShareCVDialog },
  props: {
    candidate: Object,
    candidates: Array,
    filter: Object,
  },
  mixins: [
    yearsMixins
  ],
  data: () => ({
    apiUrl: API_URL,
    certificate: false,
    // tabs: [ this.$t("form_candidate_profile"), "Actividades", "Testes Feitos", "Entrevistas", "Contratado"]
  }),
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser'
    }),
    tabs() {
      return [
        this.$t("form_candidate_profile"),
        this.$t("activities"),
        this.$t("Tests_done"),
        this.$t("interviews"),
        this.$t("Hired"), 
      ];
    },
  },
  methods: {
    dateFormat (date) {
      return moment(date).lang("pt").format('LL')
    },
       openShareDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareCandidateCV", this.candidate);
    },
    goCV () {
      this.$router.push({ path: `/dashboard/entity/cv/${this.candidate.id}`, query: { filter: this.filter, from: 'candidates' } })
    //  this.$router.push({ path: `/pdf/${this.candidate.id}`})
    //  this.openShareDialog();
    //  window.open(CLIENT+`localhost:8080_pdf_${this.candidate.id}`,"_blank")
    },
    showCertificate () {
      this.certificate = !this.certificate
    }
  }
}
</script>
<style>
.subtile {
  font-size: 1.2rem !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.small-perfil:hover {
  border: 1px solid #f7a400;
}
.show-btns:not(.on-hover) {
  color: #f7a400 !important;
}
.ajustaEspaco{
  margin-top:-3%; 
  margin-bottom:-2.5%
}
.ajustaEspaco2{
  margin-top:-3%; 
  margin-bottom:-.5%
}
@media screen and (min-width: 300px) and (max-width: 1366px) {
.ajustaEspaco{
  margin-top:-4%; 
  margin-bottom:-3.5%
}
.ajustaEspaco2{
  margin-top:-6%; 
  margin-bottom:-1.7%
}
}
</style>