<template>
  <v-dialog v-model="dialog" persistent max-width="50%">
    <v-card class="pb-5 px-5">
      <v-alert
        v-if="!ranking"
        v-model="alert"
        color="primary_text"
        dense
        type="info"
        class="text-center black--text caption mb-0"
        dismissible
        >{{ $t("Notepad_alert") }}</v-alert
      >
      <v-alert
        v-else
        v-model="alert"
        color="primary_text"
        dense
        type="info"
        class="text-center black--text caption mb-0"
        dismissible
        >{{ $t("Notepad_rankink_Alert") }}</v-alert
      >

      <v-card-title class="justify-center">
        <span class="headline">{{ $t("Notepad_notes") }}</span>
      </v-card-title>
      <!-- <pre>
  {{vacancyId.title}}
</pre> -->
      <v-card-actions class="px-5">
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              style="margin-top: -5%"
              text
              x-small
              icon
              @click="shareDi"
              color="primary"
              ><v-icon>mdi-share-variant</v-icon></v-btn
            >
          </template>
          <div v-html="$t('sharenotes')"></div>
        </v-tooltip>
      </v-card-actions>
      <v-card-text>
        <v-row>
          <!-- <pre>
            {{candidateId.id}}
          </pre> -->
          <v-col cols="12" md="12" class="scroll-test mb-5">
            <div
              class="d-flex flex-column pb-2"
              v-for="item in getNotes"
              :key="item.time"
            >
              <div>
                <span class="black--text font-weight-bold">{{
                  item.name
                }}</span>
                <span v-if="item.time" class="caption">
                  | {{ formatDate(item.time) }}</span
                >
              </div>
              <div>
                <v-hover v-slot="{ hover }" open-delay="100">
                  <span class="pt-1 showBtn">
                    {{ item.text }}

                    <v-btn
                      v-if="currentUser.name === item.name"
                      id="btn1"
                      :class="{ 'on-hover': hover }"
                      :style="hover ? 'display:inline' : ''"
                      text
                      icon
                      @click="edit(item.time, item.text)"
                      x-small
                      color="primary"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                    <v-btn
                      v-if="currentUser.name === item.name"
                      id="btn2"
                      :class="{ 'on-hover': hover }"
                      :style="hover ? 'display:inline' : ''"
                      text
                      icon
                      @click="deleteNote(item.time)"
                      x-small
                      color="primary"
                      ><v-icon>mdi-delete </v-icon></v-btn
                    >
                  </span>
                </v-hover>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- <v-virtual-scroll :items="getNotes" :item-height="50" height="350">
          <template v-slot:default="{ item }">
            <div class="d-flex flex-column pb-2">
              <div>
                <span class="black--text font-weight-bold">{{
                  item.name
                }}</span>
                <span v-if="item.time" class="caption">
                  | {{ formatDate(item.time) }}</span
                >
              </div>
              <div>
                <span class="pt-1">{{ item.text }}</span>
              </div>
            </div>
          </template>
        </v-virtual-scroll> -->
        <v-row>
          <template>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="mx-1">
                  mdi-help-circle-outline</v-icon
                >
              </template>
              <div v-if="!ranking" v-html="$t('tooltip_notpad_vacancy')"></div>
              <div v-else v-html="$t('tooltip_notpad_ranking')"></div>
            </v-tooltip>
          </template>
          <v-textarea
            v-model="note"
            :label="$t('sharenotes')"
            outlined
            dense
            auto-grow
            hide-details
            rows="1"
            class="pt-1"
          ></v-textarea>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-5">
        <v-spacer></v-spacer>
        <v-btn text small @click="$emit('close')" color="primary">{{
          $t("close")
        }}</v-btn>
        <v-btn
          :loading="isLoading"
          :disabled="isLoading"
          color="primary"
          small
          @click="addNote(flag)"
          >{{ $t("Add") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />

    <shareNote :dialog="share" />
  </v-dialog>
</template>

<script>
import {
  ADD_NOTE_CANDIDATE_MUTATION,
  DELETE_NOTE_CANDIDATE_MUTATION,
} from "./../graphql/Mutation";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import moment from "moment";
import ErrorDialog from "../../../components/ErrorDialog.vue";
import shareNote from "./../components/ShareDialogNote.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import { GET_CANDIDATE_NOTES_QUERY } from "../graphql/Query";
export default {
  name: "NoteDialog",
  components: { ErrorDialog, shareNote },
  mixins: [ADD_DIALOG],
  props: {
    candidateId: Array,
    dialog: Boolean,
    ranking: Boolean,
    vacancyId: Array,
  },
  data: () => ({
    share: false,
    alert: true,
    note: null,
    notes: [],
    params: [],
    flag: "add",
  }),
  apollo: {
    notes: {
      query: GET_CANDIDATE_NOTES_QUERY,
      variables() {
        return {
          candidateId: this.candidateId.id,
        };
      },
      fetchPolicy: "no-cache",
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    getNotes() {
      let items = this.notes;

      return items;
    },
  },
  methods: {
    ...mapActions({
      addNoteState: "vacancy/addNote",
    }),
    async deleteNote(time) {
      this.isLoading = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: DELETE_NOTE_CANDIDATE_MUTATION,
          variables: {
            candidateId: this.candidateId.id,
            time: time,
          },
        });
        this.note = null;
        console.log(data.deleteCandidateNote);
        const indexNote = this.notes.findIndex(
          (c) => c.time === data.deleteCandidateNote.time
        );
        this.notes.splice(indexNote, 1);
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async addNote(time) {
      this.isLoading = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: ADD_NOTE_CANDIDATE_MUTATION,
          variables: {
            candidateId: this.candidateId.id,
            note: this.note,
            time: time,
          },
        });
        this.note = null;

        console.log(data.addCandidateNote);
        if (this.flag === "add") {
          this.notes.unshift(data.addCandidateNote);
        } else {
          console.log(this.notes);
          this.notes = this.notes.map((note) => {
            if (note.time === data.addCandidateNote.time) {
              note.text = data.addCandidateNote.text;
            }
            return note;
          });
        }
        // this.$emit('add', data.addNoteCandidate)
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
        this.flag = "add";
      }
    },
    formatDate(date) {
      if (date) {
        const d = new Date(date).getTime();
        moment.locale(this.$i18n.locale);
        return moment(d).calendar();
      }
      return "";
    },
    edit(time, note) {
      this.flag = time;
      this.note = note;
    },
    shareDi() {
      this.share = true;
      this.params = this.candidateId
      // eslint-disable-next-line no-undef
      Fire.$emit("shareCandidateNote", this.params);
    },
  },
};
</script>

<style scoped>
.scroll-test {
  height: 320px;
  overflow: auto;
}
#btn1 {
  display: none;
}
#btn2 {
  display: none;
}
</style>
